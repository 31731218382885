.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.h1-not-found,
h2-not-found {
    font-family: Arial, sans-serif;
    text-align: center;
}

.h1-not-found {
    font-size: 10em;
    font-family: bold;
}

.h2-not-found {
    font-size: 2em;
}
