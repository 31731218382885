.formContainer {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.disabledMessage {
    color: red;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputField {
    width: 100%;
    padding: 10px;
    border-radius: 4px 0 0 4px; /* Rounded corners only on the left side */
    border: 1px solid lightgray;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;
}

.inputField:disabled {
    background-color: #f5f5f5;
    border-color: gray;
    color: gray;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0; /* Rounded corners only on the right side */
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease-in-out;
}

.submitButton:hover {
    background-color: #0056b3;
}

.submitButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
