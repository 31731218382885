.left-container-login {
    flex-direction: column;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.btn-sm {
    margin-top: -5rem;
}
.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 768px) {
    .btn-sm {
        /* margin-top: -5rem; */
        margin-left: -8rem;
    }

    .resetingcard {
        height: 35rem !important;
        /* width: 100rem !important; */
    }
}

.resetingcard {
    /* margin-top: 5rem; */
    height: 23rem;
    width: 50rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.textCenter {
    text-align: center;
}

.card-login {
    /* margin-top: 3rem; */
    background-color: #fff; /* White background */
    border: 1px solid rgba(0, 0, 0, 0.125); /* Gray border */
    border-radius: 0.25rem; /* Rounded corners */
    padding: 20px; /* Padding inside the card */
}
