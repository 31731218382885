.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.profileBodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.profileBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 40px;

    max-width: 90vw;
}

.skillBlockContainer {
    display: flex;
    flex-wrap: row;
    justify-content: center;
    color: white;
    /* For some reason this border is not being applied correctly, lol */
    border-radius: 30px;
    padding: 3px;
}

.skillBlock {
    max-width: 30%;
    width: auto;
    text-align: center;
    padding: 1rem;
}

.textInnerContainer {
    text-align: center;
    margin-bottom: 10px;
}

.bg-gray {
    background-color: rgb(83, 83, 83);
}
