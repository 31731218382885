.comments-container {
    background: #fff;
    max-width: 450px;
    width: 40rem;
    border-radius: 4px;
    padding: 25px;
    margin: 20px 0;
    margin-top: -0.7rem;
    margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
    .comments-container {
        width: 90%;
    }
}

.commentForm {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
}

.comment-item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e6e6e6;
}

.comment-item .commentProfileInfoContainer {
    display: none;
}

.comment-item.active .commentProfileInfoContainer {
    display: flex;
}

.comment-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    width: 100%;
}

.first-part-comment-header {
    display: flex;
    align-items: start;
    justify-content: row;
    padding-bottom: 0.5rem;
    width: 100%;
}

.profile-picture {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer; /* Indicate that it's clickable */
}

.comment-content {
    margin-left: 50px;
    word-wrap: break-word;
    margin-bottom: 0.5rem;
}

.delete-icon {
    margin-left: auto;
}

.formTextArea {
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
}

.commentProfileInfoContainer {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 5px;
}
.commentProfileInfowraper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 50%;
}
.commentProfileInfoColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.commentProfileInfoColumnTitle {
    font-size: 0.8rem;
    font-weight: bold;
}

.commentProfileInfoColumnContentLikes {
    background-color: green;
    color: white;
    padding: 5px 13px;
    border-radius: 50%;
}
.commentProfileInfoColumnContentDislikes {
    background-color: red;
    color: white;
    padding: 5px 13px;
    border-radius: 50%;
}
.commentProfileInfoColumnContentComments {
    background-color: gray;
    color: white;
    padding: 5px 13px;
    border-radius: 50%;
}

.bold {
    font-weight: bold;
}
