.pictureContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
}

.profilePicture {
    position: relative;
    width: 23.3rem;
    height: 23.3rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ddd;
    border-radius: 8px;
    margin: auto;
}

/* Medium big screen */
@media (min-width: 991px) and (max-width: 1200px) {
    .profilePicture {
        width: 23.3rem;
        height: 23.3rem;
    }
}

/* Big big screen */
@media (min-width: 1200px) {
    .profilePicture {
        width: 23.3rem;
        height: 23.3rem;
    }
}

/* Small screens */
@media (max-width: 768px) {
    .profilePicture {
        width: 25.3rem;
        height: 23.3rem;
        max-width: 80%;
    }
}

.profileImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uploadButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
}

.uploadButton input {
    display: none;
}

.errorMessage {
    color: red;
    margin-top: 10px;
}
