.mainContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    padding: 1rem;
}

.resetCard {
    padding: 2rem;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 30rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backButton {
    padding: 8px 15px;
    align-self: flex-start;
    margin-bottom: 1rem;
    color: #ffffff;
    text-decoration: none;
    font-size: 0.875rem;
    font-size: 17px;
}

.title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formLabel {
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
}

.inputField {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    font-size: 1rem;
}

.errorMessage {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease-in-out;
}

.submitButton:hover {
    background-color: #0056b3;
}
