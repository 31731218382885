.like {
    fill: rgb(47, 47, 255);
}
.dislike {
    fill: red;
}
.none {
    fill: grey;
}

.alignTextCenter {
    text-align: center;
}

.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.text-holder-no-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    gap: 3rem;
}
.text-holder-no-categories-second {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.btn-bg-modified {
    background-color: #6c757d !important;
    border: none;
}
.btn-bg-modified:hover {
    background-color: #007bff !important;
}

.centered-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    font-weight: bold;
}
.post-counter {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 8rem;
}
.load-more {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-weight: bold;
    padding-bottom: 40px;
}
.load-more:hover {
    color: darkblue;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
.question {
    width: 100%;
}
textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}
::selection {
    color: #fff;
    background: #1da1f2;
}
.wrapper {
    position: relative;
    background: #fff;
    max-width: 650px;
    width: 90vw;
    border-radius: 15px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 50px;
}
.input-box {
    padding-top: 10px;
    border-bottom: 1px solid #e6e6e6;
}
.input-box .tweet-area {
    position: relative;
    min-height: 130px;
    max-height: 170px;
    overflow-y: auto;
}
.tweet-area::-webkit-scrollbar {
    width: 0px;
}
.tweet-area .placeholder {
    position: absolute;
    margin-top: -3px;
    font-size: 22px;
    color: #98a5b1;
    pointer-events: none;
}
.tweet-area .input {
    outline: none;
    font-size: 17px;
    min-height: inherit;
    word-wrap: break-word;
    word-break: break-all;
}
.tweet-area .editable {
    position: relative;
    z-index: 5;
}
.tweet-area .readonly {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: transparent;
    background: transparent;
}
.readonly .highlight {
    background: #fd9bb0;
}
.input-box .privacy {
    color: #1da1f2;
    margin: 15px 0;
    display: inline-flex;
    align-items: center;
    padding: 7px 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.2s ease;
}
.privacy:hover,
.icons li:hover {
    background: #e7f5fe;
}
.privacy i {
    font-size: 18px;
}
.privacy span {
    font-size: 15px;
    font-weight: 600;
    margin-left: 7px;
}
.bottom {
    display: flex;
    margin-top: 13px;
    align-items: center;
    justify-content: space-between;
}
.bottom .icons {
    display: inline-flex;
}
.icons li {
    list-style: none;
    color: #1da1f2;
    font-size: 20px;
    margin: 0 2px;
    height: 38px;
    width: 38px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background 0.2s ease;
}
.bottom .content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom .counter {
    color: #333;
    display: none;
    font-weight: 500;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #aab8c2;
}
.bottom button {
    padding: 9px 18px;
    border: none;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    background: #0d8bd9;
    color: #fff;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;
    transition: background 0.2s ease;
}
.bottom button.active {
    opacity: 1;
    pointer-events: auto;
}
.bottom button:hover {
    background: #0d8bd9;
}

.categories-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
}
.categories-section form {
    width: 70%;
}

.input-group-append button {
    background-color: #6c757d;
    border: none;
}
.input-group-append button:hover {
    background-color: #007bff;
}

.input-group {
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .input-group {
        margin-bottom: 8rem;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}
::selection {
    color: #fff;
    background: #1da1f2;
}
.wrapper {
    background: #fff;
    max-width: 700px;
    width: 100%;
    border-radius: 15px;
    padding: 25px 25px 25px 25px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    margin: 0;
}
.input-box {
    padding-top: 10px;
    border-bottom: 1px solid #e6e6e6;
}
.input-box .tweet-area {
    position: relative;
    max-height: 170px;
    overflow-y: auto;
}
.tweet-area::-webkit-scrollbar {
    width: 0px;
}
.tweet-area .placeholder {
    position: absolute;
    margin-top: -3px;
    font-size: 22px;
    color: #98a5b1;
    pointer-events: none;
}
.tweet-area .input {
    outline: none;
    font-size: 17px;
    min-height: inherit;
    word-wrap: break-word;
    word-break: break-all;
}
.tweet-area .editable {
    position: relative;
    z-index: 5;
}
.tweet-area .readonly {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: transparent;
    background: transparent;
}
.readonly .highlight {
    background: #fd9bb0;
}
.input-box .privacy {
    color: #1da1f2;
    margin: 15px 0;
    display: inline-flex;
    align-items: center;
    padding: 7px 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.2s ease;
}
.privacy:hover,
.icons li:hover {
    background: #e7f5fe;
}
.privacy i {
    font-size: 18px;
}
.privacy span {
    font-size: 15px;
    font-weight: 600;
    margin-left: 7px;
}
.bottom {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
}
.bottom .icons {
    display: inline-flex;
}
.icons li {
    list-style: none;
    color: #1da1f2;
    font-size: 20px;
    margin: 0 2px;
    height: 38px;
    width: 38px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background 0.2s ease;
}
.bottom .content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom .counter {
    color: #333;
    display: none;
    font-weight: 500;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #aab8c2;
}
.bottom button {
    padding: 9px 18px;
    border: none;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    background: #0d8bd9;
    color: #fff;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;
    transition: background 0.2s ease;
}
.bottom button.active {
    opacity: 1;
    pointer-events: auto;
}
.bottom button:hover {
    background: #0d8bd9;
}

.commentsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensure full width */
    margin: 0 auto; /* Centering with auto margins */
}

.commentItem {
    width: 100%; /* Make sure each comment takes full width */
    max-width: 700px; /* Optional: limit max width */
    margin-bottom: 10px; /* Space between comments */
    text-align: center; /* Center text alignment */
}
