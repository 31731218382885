#post-container {
    width: 90vw;
}

#cats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.delete-icon {
    align-self: flex-end;
    cursor: pointer;
    color: red;
    margin-bottom: 10px;
}

.like {
    color: blue;
    cursor: pointer;
}
.dislike {
    color: red;
    cursor: pointer;
}

.none {
    color: grey;
    cursor: pointer;
}

.commentButton {
    border: none;
    cursor: pointer;
}

.boldContent {
    font-weight: bold;
}
