.profile-picture-container {
    text-align: center;
    margin-top: 20px;
}

.profile-picture {
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upload-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
}

.upload-button:hover {
    background-color: rgba(19, 19, 19, 0.5);
}

.error-message {
    color: red;
    margin-top: 10px;
}

body {
    background-color: #eeeeee;
}
