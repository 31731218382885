.vh100 {
    height: 100vh;
}

.card {
    border-radius: 25px;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90vw;
    max-width: 90%;
}

.formLabel {
    margin-bottom: 0.5rem;
}

.iconContainer i {
    font-size: 1.5rem;
}

.icon {
    margin-right: 0.8rem;
}
.flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.btnDark {
    background-color: #343a40;
    border: none;
}

.btnDark:hover {
    background-color: #23272b;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profilePictureContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
