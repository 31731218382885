.filterContainer {
    width: 100%;
    height: auto;
    background-color: rgb(28, 28, 28);
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5rem;
}

.filterContainer::-webkit-scrollbar {
    /* WebKit */
    display: none;
}

.hover {
    cursor: pointer;
}

.categories-container {
    display: grid;
    gap: 0.5rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    margin-left: 7rem;
    margin-right: 7rem;
    grid-template-columns: repeat(5, 1fr); /* 5 columns on larger screens */
}

.category-item {
    display: flex;
    justify-content: center;
}

.category-item-btn,
.category-item-btn-unselected {
    width: 100%;
}

.category-item-btn {
    background-color: #007bff;
    font-weight: bold;
    color: white;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.category-item-btn-unselected {
    background-color: #6c757d;
    font-size: medium;
    font-weight: bold;
    color: white;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.category-item-btn-unselected:hover {
    background-color: #848b91;
}

@media (max-width: 768px) {
    .categories-container {
        grid-template-columns: repeat(
            2,
            1fr
        ); /* 2 columns on smaller screens */
    }
}
