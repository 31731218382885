.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.headerTitle {
    text-align: center;
    margin-bottom: 20px;
}

.tableContainer,
.formContainer {
    width: 60rem;
    max-width: 90%;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
}

.tableContainer {
    margin-bottom: 30px;
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
