.container-edit-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    max-width: 30rem;
    width: 80%;
    border-radius: 20px;
    padding: 2rem;
    margin-top: 2rem;
}

.centering-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-start-container {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding-bottom: 1rem;
}
